import * as React from 'react';
import { graphql } from 'gatsby';
import { EntityExplorerLayout } from '../../components/entities/entity-explorer-layout';
import { createGatsbyPageComponent } from '@shapeable/web';

export default createGatsbyPageComponent('Trend', { layout: EntityExplorerLayout });

export const trendQuery = graphql`
  query TrendQuery($id: ID!) {
    platform {
      trend(id: $id) {
        __typename _schema { label pluralLabel }
        id slug name __typename outlineNumber
        color { __typename id slug name value }
        description { text }
        intro { text }
        openGraph { 
          title description { plain }
          image { url thumbnails { large { url } } }
        }
        banner {
          id
          thumbnail { url } 
          image { 
            thumbnails {
              mainBanner { id url }
            }
            url 
          }
          

        }
        feedEntries {
          id slug path url name __typename _schema { label pluralLabel }
          types { id name slug }
          openGraph { 
            title description { plain }
            image { url thumbnails { large { url } } }
          }
        }
        posts {
          id slug name __typename path
          openGraph { 
            title description { plain }
            image { url thumbnails { large { url } } }
          }
        }
        citations {
          __typename _schema { label pluralLabel }
          id path slug url name
          authors { id name slug } authorShowsEtAl edition publication date accessDate startPage volume footnoteNumber year
        }
        topics {
          id name outlineNumber slug path __typename 
          # trendIntro { text }
          intro { text(truncate: {ellipsis: "...", words: 45}) }
          color { id name value } 
          banner {
            id
            thumbnail { url static { childImageSharp { gatsbyImageData(placeholder: NONE) } } } 
            image { url } 
          }
        }
        horizons {
          name path slug _schema { label pluralLabel }
          type { id name years }
          description { text }
        }
        pages {
          __typename id name path slug title subtitle pretitle 
          openGraph { description { text } image { url } }
          intro { text }
          orderNumber
          type { id name slug }
          banner {
            name slug alternateText
            thumbnail { 
              id url width height static { childImageSharp { gatsbyImageData(placeholder: NONE) } } 
            }
          }
        }
      }
    }
  }
`;
